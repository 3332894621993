import Features from "../../components/Home/Features";
import Footer from "../../components/Footer";
import Downbar from "../../components/down_footer_pulizie";
import Navbar from "../../components/Navbar";
import Card from "../../components/Card";
import { Helmet } from "react-helmet"
import * as React from "react";
import "../../styles/main.css";
import { pageStyles,cityDiv,mapDiv,mapSize } from "../../styles/style";
import { StaticImage } from "gatsby-plugin-image";
import city from "../../images/copertura/roma.jpg";
import FloatButton from "../../components/FloatButton";

// manutenpul styles
// data
// cards services

const services = [
  {
    'image': <StaticImage src="../../images/services/pulizie_uffici.webp" alt="SERVIZI DI PULIZIA" />,
    'title':'SERVIZI DI PULIZIA',
    'text':'Effettuiamo Pulizie Professionali- Pulizie Uffici, Negozi, Palestre, Studi.- Pulizie Condominiali.- Pulizie in Quota fino a 20 mt.- Sanificazione Bagni, Cucine, Mense. - Pulizie Post-Ristrutturazione.- Pulizie Strutture Sanitarie.',
  },{
    'image':<StaticImage src="../../images/services/sanificazione.webp" alt="SANIFICAZIONE" />,
    'title':'SANIFICAZIONE',
    'text':'Eseguiamo servizi di sanificazione per qualsiasi ambiente civile ed industriale, a Milano e provincia (anche contro Coronvirus-COVID 19). Non è sufficiente igienizzare, ma occorre sanificare e, quindi, abbattere le cariche microbiche per ridurre il rischio di insorgenza e diffusione di patologie ed infezioni.',
  },{
    'image':<StaticImage src="../../images/services/disinfestazioni.webp" alt="DISINFESTAZIONI" />,
    'title':'DISINFESTAZIONI',
    'text':'L’esperienza maturata ci permette oggi, di poter risolvere ogni genere di minaccia accompagnandovi nelle varie fasi di intervento fino alla completa estinzione dell’infestazione. Rivolgiamo i nostri servizi di disinfestazione sia ad utenti privati che a strutture pubbliche e tutti gli interventi sono svolti in rispetto del protocollo HACCP e tutti i prodotti sono presidi medico chirurgici e dotati di scheda tecnica e sicurezza.',
  },{
    'image':<StaticImage src="../../images/services/giardinaggio.webp" alt="GIARDINAGGIO" />,
    'title':'GIARDINAGGIO',
    'text':'Un team di giardinieri si occupa di proteggere e preservare le aree verdi di immobili privati e pubblici.',
  },{
    'image':<StaticImage src="../../images/services/furgon-1.webp" alt="SGOMBERI TRASLOCHI" />,
    'title':'SGOMBERI TRASLOCHI',
    'text':'Si eseguono con puntualità e professionalità sgomberi per privati: cantine, solai, depositi, appartamenti e anche per uffici o attività commerciali.',
  },{
    'image':<StaticImage src="../../images/services/servizi-edili.webp" alt="SERVIZI EDILI" />,
    'title':'SERVIZI EDILI',
    'text':"Si effettuano ristrutturazioni edilizie complete per abitazioni residenziali, appartamenti e locali commerciali. Il team dell'impresa si occupa di ogni fase della ristrutturazione.",
  }
];

// markup
const RomaPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
          <meta charSet="utf-8" />
          <title>Impresa di Pulizie e Servizi - MANUTENPUL</title>
          <meta name="description" content="Impresa di pulizie Roma: MANUTENPUL dal 1990. Affidabilità e tempestività contraddistinguno i nostri interventi di pulizia e sanificazione. Preventivo GRATUITO! Impresa di Pulizie Roma - Servizi di Pulizie Roma - Pulizie Professionali Roma"></meta>
          <meta name="keywords" content="impresa di pulizie Roma, impresa di pulizie Roma prezzi, impresa pulizie costi Roma, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi"></meta>
      </Helmet>
      <Navbar></Navbar>
      <Downbar></Downbar>
      <section className="feature-section pt-110">
        <div className="container">
          <div className="row">
              <div className="col-12" style={cityDiv}>
                <img src={city} width="100%" alt="city" />
              </div>
              <div className="col-12" style={mapDiv}>
                <iframe title="MiniMaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190029.01772612485!2d12.395915674207043!3d41.9099859758667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f6196f9928ebb%3A0xb90f770693656e38!2sRoma%2C%20Italia!5e0!3m2!1ses-419!2smx!4v1637308359761!5m2!1ses-419!2smx" width="100%" height="450" style={mapSize} loading="lazy"></iframe>
              </div>
           </div>
        </div>
      </section>
      <Features 
      cityName={"ROMA"}
      text={"Abbiamo copertura a Roma.  "}
      />
      <section id="blog" className="blog-section pt-130 pb-100">
        <div className="container">
        {/* <div className="row align-items-end"></div> */}
          <div className="row">
            {services.map(service => (
              <Card
              key={service.title}
              image={service.image}
              title={service.title}
              text={service.text} />
            ))}
          </div>
        </div>
      </section>
      <Footer></Footer>
    </main>
  )
}

export default RomaPage;